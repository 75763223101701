import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withJsonpSupport } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { connectAuthEmulator, getAuth, provideAuth } from '@angular/fire/auth';
import {
    getRemoteConfig,
    provideRemoteConfig,
} from '@angular/fire/remote-config';
import { connectStorageEmulator, getStorage, provideStorage } from "@angular/fire/storage";
import { connectDatabaseEmulator, getDatabase, provideDatabase } from '@angular/fire/database';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';
import { LoginComponent } from './application/components/login/login.component';
import { NotificationsPanelComponent } from "./application/components/notifications-panel/notifications-panel.component";
import { CoreRoutingModule } from './core-routing.module';
import { SharedModule } from '../shared/shared.module';
import { AppComponent } from './application/components/app.component';
import { FooterComponent } from './application/components/footer/footer.component';
import { HeaderComponent } from './application/components/header/header.component';
import { SidebarComponent } from './application/components/sidebar/sidebar.component';
import { TokenInterceptor } from './application/interceptors/token.interceptor';
import { BROWSER_FAVICONS_CONFIG, BrowserFavicons, Favicons } from './application/services/browser-favicons.service';
import { ConfirmModalComponent, ConfirmService, ConfirmState, ConfirmTemplateDirective } from './application/services/confirm-modal.service';
import { ErrorInterceptor } from './application/interceptors/error.interceptor';
import { ProfilePanelComponent } from './application/components/profile-panel/profile-panel.component';
import { RecoverFormComponent } from './application/components/login/components/recover-form/recover-form.component';
import { DebugPanelComponent } from './application/components/debug-panel/debug-panel.component';
import { ExportResultsComponent } from './application/components/export-results/export-results.component';
import { AppJsonpInterceptor } from './application/interceptors/jsonp.interceptor';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        SidebarComponent,
        LoginComponent,
        ConfirmModalComponent,
        ConfirmTemplateDirective,
        ProfilePanelComponent,
        RecoverFormComponent,
        DebugPanelComponent,
        ExportResultsComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        CoreRoutingModule,
        SharedModule,
        ReactiveFormsModule,
        FormsModule,
        NotificationsPanelComponent,
    ],
    exports: [
        SidebarComponent,
        HeaderComponent,
        FooterComponent,
    ],
    providers: [
        CookieService,
        ConfirmService,
        ConfirmState,
        provideHttpClient(
            withInterceptors([
                TokenInterceptor,
                ErrorInterceptor,
            ])
        ),
        { provide: HTTP_INTERCEPTORS, useClass: AppJsonpInterceptor, multi: true },
        { provide: Favicons, useClass: BrowserFavicons },
        {
            provide: BROWSER_FAVICONS_CONFIG,
            useValue: {
                icons: {
                    prod: {
                        type: 'image/png',
                        href: './assets/img/favicon.png',
                        isDefault: true
                    },
                    develop: {
                        type: 'image/png',
                        href: './assets/img/favicon_dev.png'
                    },
                },
                cacheBusting: true
            }
        },
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideStorage(() => {
            // Add default bucket
            const storage = getStorage();
            if (environment.firebase.authDomain === 'localhost')
                connectStorageEmulator(storage, '127.0.0.1', 9199);
            return storage;
        }),
        provideStorage(() => {
            // Add customerReturns bucket
            const storage = getStorage(getApp(), environment.storageBuckets.customerReturns);
            if (environment.firebase.authDomain === 'localhost')
                connectStorageEmulator(storage, '127.0.0.1', 9199);
            return storage;
        }),
        provideStorage(() => {
            // Add boodmoFileImports bucket
            const storage = getStorage(getApp(), environment.storageBuckets.boodmoFileImports);
            if (environment.firebase.authDomain === 'localhost')
                connectStorageEmulator(storage, '127.0.0.1', 9199);
            return storage;
        }),
        provideDatabase(() => {
            const database = getDatabase();
            if (environment.firebase.authDomain === 'localhost')
                connectDatabaseEmulator(database, 'localhost', 9000);
            return database;
        }),
        provideDatabase(() => {
            const database = getDatabase(getApp(), environment.firebaseDatabases[1]);
            if (environment.firebase.authDomain === 'localhost')
                connectDatabaseEmulator(database, 'localhost', 9000);
            return database;
        }),
        provideRemoteConfig(() => {
            const remoteConfig = getRemoteConfig();
            remoteConfig.settings.minimumFetchIntervalMillis =
                environment.firebaseRemoteConfig.minimumFetchIntervalMillis;
            return remoteConfig;
        }),
        provideAuth(() => {
            const auth = getAuth();
            if (environment.firebase.authDomain === 'localhost') {
                connectAuthEmulator(auth, 'http://localhost:9099', {
                    disableWarnings: true,
                });
            }
            return auth;
        }),
    ],
    bootstrap: [AppComponent]
})
export class CoreModule
{
}
